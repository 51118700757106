import { createGlobalStyle } from 'styled-components';

import ChaletLondonSixtyWoff from '@assets/fonts/Chalet-LondonSixty.woff';
import ChaletLondonSixtyWoff2 from '@assets/fonts/Chalet-LondonSixty.woff2';
import ChaletNewYorkSixtyWoff from '@assets/fonts/Chalet-NewYorkSixty.woff';
import ChaletNewYorkSixtyWoff2 from '@assets/fonts/Chalet-NewYorkSixty.woff2';
import HattonLightWoff from '@assets/fonts/Hatton-Light.woff';
import HattonLightWoff2 from '@assets/fonts/Hatton-Light.woff2';
import HattonRegularWoff from '@assets/fonts/Hatton-Regular.woff';
import HattonRegularWoff2 from '@assets/fonts/Hatton-Regular.woff2';
import MontserratSemibold from '@assets/fonts/montserrat-semibold-webfont.woff';
import MontserratSemibold2 from '@assets/fonts/montserrat-semibold-webfont.woff2';

import theme from '@theme/WsTheme';

export const FontStyles = createGlobalStyle`

@font-face {
	font-family: 'Chalet';
	src: url('${ChaletLondonSixtyWoff2}') format('woff2'),
		url('${ChaletLondonSixtyWoff}') format('woff');
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Chalet';
	src: url('${ChaletNewYorkSixtyWoff2}') format('woff2'),
		url('${ChaletNewYorkSixtyWoff}') format('woff');
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Chalet-LondonSixty';
	src: url('${ChaletLondonSixtyWoff2}') format('woff2'),
		url('${ChaletLondonSixtyWoff}') format('woff');
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Chalet-NewYorkSixty';
	src: url('${ChaletNewYorkSixtyWoff2}') format('woff2'),
		url('${ChaletNewYorkSixtyWoff}') format('woff');
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
	font-display: swap;

}

@font-face {
	font-family: 'Hatton';
	src: url('${HattonRegularWoff2}') format('woff2'),
		url('${HattonRegularWoff}') format('woff');
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
	font-display: swap;

}

@font-face {
	font-family: 'Hatton';
	src: url('${HattonLightWoff2}') format('woff2'),
		url('${HattonLightWoff}') format('woff');
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
	font-display: swap;

}

@font-face {
	font-family: 'Montserrat';
	src: url('${MontserratSemibold2}') format('woff2'),
		url('${MontserratSemibold}') format('woff');
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
	font-display: swap;
}
`;

export const ResetStyles = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
	background-color: ${theme.colors.lightBeige};
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
strong {
	font-family: Chalet-NewYorkSixty, sans-serif;
}
button {
	appearance: none;
	border: none;
	padding: 0;
	background: none;
}
`;

export const GlobalStyles = createGlobalStyle`
html {
	font-size: 62.5%;
}

html,
body {
	font-family: Chalet-LondonSixty, sans-serif;
	color: ${theme.colors.black};
}

body {
	background: ${theme.colors.lightBeige};
	-webkit-font-smoothing: antialiased;
	&.dark {
		background: ${theme.colors.black};
	}
}

h1 {
	font-size: ${theme.fontSizes.header.xl};
	line-height: ${theme.lineHeights.header.xl};
}

h2 {
	font-size: ${theme.fontSizes.header.lg};
	line-height: ${theme.lineHeights.header.lg};
}

h3 {
	font-family: Hatton, serif;
	font-size: ${theme.fontSizes.header.lg};
	line-height: ${theme.lineHeights.header.lgh};
}

h4 {
	font-size: ${theme.fontSizes.header.md};
	line-height: ${theme.lineHeights.header.md};
}

h5 {
	font-family: Chalet-NewYorkSixty, sans-serif;
	font-size: ${theme.fontSizes.header.sm};
	line-height: ${theme.lineHeights.header.sm};
}

h6 {
	font-family: Hatton, serif;
	font-size: ${theme.fontSizes.header.xs};
	line-height: ${theme.lineHeights.header.xs};
}

// @a11y
.skip-to-content-link {
  background: ${theme.colors.white};
  padding: 1rem 1.5rem;
  top: 0;
	left: 0;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
	color: ${theme.colors.black};
	font-size: ${theme.fontSizes.body.sm};
	border: 2px solid ${theme.colors.beige};
	z-index: ${theme.zIndexes.dialog};
	text-decoration: none;
	&:focus {
		transform: translateY(0%);
	}
}

// @fix for routes under reach-router
div[tabindex = '-1'] {
	flex: 1;
	width: inherit;
	display: contents;
	// Other rules that don't break a11y 😊
}

// @global overlay modals
div[data-reach-dialog-overlay] {
	display: block;
	z-index: ${theme.zIndexes.dialog};
	background: rgba(248, 247, 245, 0.7);
}

// @fix for library menu
div[data-reach-menu-popover] {
	display: block;
	z-index: ${theme.zIndexes.dialog};
}

div[data-reach-listbox-popover] {
	display: block;
	max-height: 32rem;
	overflow: auto;
	z-index: ${theme.zIndexes.dialog};
}
`;
