import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
	switch (action.type) {
		case 'add':
			return { count: ++state.count };
		case 'subtract':
			return { count: --state.count };
		default:
			return state;
	}
};

const initialState = { count: 0 };

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
