import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { createContext } from 'react';

import { ApiEndpoints } from '@utils/endpoints';
import { isBrowser } from '@utils/isBrowser';

import { authLink, errorLink } from '@providers/ApolloProvider';

export interface GraphQLV2ContextProps {
	client: ApolloClient<any>;
}

export const graphQLv2ApolloClient = () => {
	const httpLink = new HttpLink({
		uri: ApiEndpoints.folxGraphqlV2,
		...(!isBrowser && {
			fetch: () => {
				throw new Error("Can't make a dynamic query outside of a browser.");
			},
		}),
	});

	const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link,
	});

	return client;
};

const GraphQLv2Context = createContext<GraphQLV2ContextProps>({
	client: graphQLv2ApolloClient(),
});

export default GraphQLv2Context;
