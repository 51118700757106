import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { useEffect, useState } from 'react';

import { isBrowser } from '@utils/isBrowser';

interface HookReturnType {
	trackEvent: (label: string, metadata?: Object | undefined) => void;
}

export const useTracking = (
	label?: string | undefined,
	metadata?: Object | undefined
): HookReturnType => {
	const mixpanel = useMixpanel();
	const trackEvent = (label: string, metadata: Record<string, unknown> | undefined) => {
		mixpanel.track(label, metadata);
	};

	const [trackingDone, setTrackingDone] = useState<boolean>(false);

	useEffect(() => {
		if (!isBrowser) {
			return;
		}
		if (label) {
			if (!trackingDone) {
				setTrackingDone(true);
				trackEvent(label, metadata);
			}
		}
	});

	return {
		trackEvent,
	};
};
