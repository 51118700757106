import { gql, useQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';

import { useAuth } from '@providers/AuthProvider';

export const isBrowser = typeof window !== `undefined`;

interface IEntitlementsContext {
	communitySpaces: boolean;
}

const COMMUNITY_LOGIN_RIGHTS_QUERY = gql`
	query memberHasCommunityLoginRights {
		memberHasCommunityLoginRights
	}
`;

interface IMemberHasCommunityLoginRights {
	memberHasCommunityLoginRights: boolean;
}

const EntitlementsContext = React.createContext<IEntitlementsContext>({
	communitySpaces: false,
});

export const EntitlementsProvider: FC = ({ children }) => {
	const [entitlements, setEntitlements] = useState<IEntitlementsContext>({
		communitySpaces: false,
	});

	const { userId } = useAuth();

	useQuery<IMemberHasCommunityLoginRights>(COMMUNITY_LOGIN_RIGHTS_QUERY, {
		skip: !userId,

		onCompleted: (memberHasCommunityLoginRights: IMemberHasCommunityLoginRights) => {
			setEntitlements({
				communitySpaces: memberHasCommunityLoginRights.memberHasCommunityLoginRights,
			});
		},
	});

	return (
		<EntitlementsContext.Provider value={entitlements}>{children}</EntitlementsContext.Provider>
	);
};

export const useEntitlements = () => {
	const { communitySpaces } = useContext(EntitlementsContext);
	return {
		communitySpaces,
	};
};
