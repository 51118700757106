import { Auth, Hub, Storage } from 'aws-amplify';
import React from 'react';
import Helmet from 'react-helmet';

import Layout from './src/components/Layout';
import { isBrowser } from './src/utils/isBrowser';

export default ({ element, props: { location }, ...props }) => {
	if (isBrowser) {
		Auth?.configure({
			Auth: {
				mandatorySignIn: true,
				region: process.env.GATSBY_COGNITO_REGION,
				userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
				userPoolWebClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
				identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
				cookieStorage: {
					domain: process.env.GATSBY_COOKIE_DOMAIN ?? 'localhost',
				},
			},
			Storage: {
				AWSS3: {
					bucket: process.env.GATSBY_S3_BUCKET,
					region: process.env.GATSBY_S3_REGION,
					level: 'private',
				},
			},
		});

		const updateIdentity = (identity: string) => {
			Storage?.configure({
				AWSS3: {
					identityId: identity,
				},
			});
		};

		Hub.listen('auth', (data) => {
			switch (data.payload.event) {
				case 'signIn':
				case 'configured':
					Auth.currentSession()
						.then((cognitoSession) => {
							updateIdentity(cognitoSession.getIdToken().payload['cognito:username']);
						})
						.catch((e) => updateIdentity(''));
					break;
				case 'signOut':
					updateIdentity('');
					break;
			}
		});
	}

	return (
		<Layout {...props} location={location}>
			{element}
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
				defaultTitle="FOLX Health"
				titleTemplate="FOLX Health - %s">
				<script type="text/javascript">
					{`
					(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
					vgo('setAccount', '67306142');
					vgo('setTrackByDefault', true);

					vgo('process');
					`}
				</script>
			</Helmet>
		</Layout>
	);
};
