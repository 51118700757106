import { Functor, map } from 'ramda';

let ApiEndpoints: { [key: string]: string } = {
	pdpEndpoint: `/public/pdp-data`,
	intakeFileUpload: `/public/intake-file`,
	submitFeedback: `/public/submit-feedback`,
	stripe: `/stripe`,
	portalStatus: `/ehr/get-portal-status`,
	optimizelyTemplate: `/public/get-optimizely-datafile/%s`,
	folxGraphql: `/graphql`,
	folxGraphqlV2: `/graphql-v2`,
	leadCapture: `/contact`,
	pricing: `/stripe/pricing`,
	medPricing: `/public/medication-pricing`,
	memberPortal: `/member-portal`,
	deliveryMethod: `/member-portal/delivery-method`,
	memberOnboarding: `/public/onboard`,
	surveys: `/surveys`,
	memberOnboardingConfirmation: `/public/onboard-confirm`,
	membershipOnboarding: `/public/membership-onboarding`,
	resendRegistrationCode: `/public/resend-registration-code`,
	cogsworthValidateAppointment: `/cogsworth/get-appointment-valid`,
	cogsworthConfirmAppointment: `/cogsworth/confirm-valid-appointment`,
	cogsworth: `/cogsworth`,
	public: `/public`,
};

ApiEndpoints = map(
	(endpoint) => `${process.env.GATSBY_FOLX_API_BASE!}${endpoint}`,
	ApiEndpoints as Functor<string>
);

export { ApiEndpoints };
