module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FOLX Health","short_name":"Folx","start_url":"/","display":"standalone","icon":"/codebuild/output/src1017260122/src/member-frontend/src/assets/images/ampersex.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc38cca04c4b04ea9469de1f3b9d8f18"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"f5f7923399fddd8eae06a6b44ad6a881","mixpanelConfig":{"api_host":"https://mpp.folxhealth.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.folxhealth.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"applicationId":"d3308c14-bb10-4578-8802-664b34181355","clientToken":"pub132b8f9026fe56e41403c604f425fa3a","site":"datadoghq.com","service":"member-webapp","version":"amplify-b0000001614-rfbc3537559a816a40e5fa559a5102c99b9251cf3","env":"prod","allowedTracingUrls":["https://api.folxhealth.com"],"sessionSampleRate":100,"sessionReplaySampleRate":20,"trackUserInteractions":true,"trackResources":true,"trackLongTasks":true,"defaultPrivacyLevel":"mask-user-input"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
