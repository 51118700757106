import { shade, tint, transparentize } from 'polished';

import {
	SpacingTypes,
	ColorTypes,
	RadiusTypes,
	BreakpointTypes,
	FontSizeTypes,
} from '@folx-types/theme';

const size = {
	base: '0px',
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopM: '1280px',
	laptopL: '1440px',
	desktop: '2560px',
};

const colors: { [Type in ColorTypes]: string } = {
	transparent: '#00000000',
	black: '#000000',
	white: '#ffffff',
	cyan: '#00ffff',
	lightCyan: '#99feff',
	darkCyan: '#9cc7c7',
	yellow: '#ffff00',
	red: '#ff0000',
	lightRed: '#e9c0c0',
	cardRed: '#f0e2e2',
	darkRed: '#990000',
	green: '#dafabc',
	lilac: '#c79bff',
	lilacMedium: '#9f7bcc',
	lilacLight: '#f6efff',
	lavender: '#ddd7e3',
	lightLavender: '#eee5f8',
	redOrange: '#ff9999',
	cardBeige: '#f0eae2',
	lightBeige: '#f8f7f5',
	beige: '#f4e3cf',
	darkBeige: '#e9d4c0',
	pink: '#ffa1d3',
	darkPink: '#f57eb6',
	lightPink: '#fccbe5',
	gray: '#ebebeb',
	darkGray: '#d8d8d8',
	lightGray: '#f4f4f4',
	disabledGray: '#f2f4f7',
	borderGray: '#dbdee1',
	modalGray: '#0000004f',
};

const tints = {
	darken20: shade(0.2),
	darken30: shade(0.3),
	darken40: shade(0.4),
	darken50: shade(0.5),
	darken60: shade(0.6),
	darken70: shade(0.7),
	darken80: shade(0.8),
	lighten20: tint(0.2),
	lighten30: tint(0.3),
	lighten40: tint(0.4),
	lighten50: tint(0.5),
	lighten60: tint(0.6),
	lighten70: tint(0.7),
	lighten80: tint(0.8),
};

const transparency = {
	transparency10: transparentize(0.1),
	transparency15: transparentize(0.15),
	transparency20: transparentize(0.2),
	transparency25: transparentize(0.25),
	transparency30: transparentize(0.3),
	transparency35: transparentize(0.35),
	transparency40: transparentize(0.4),
	transparency45: transparentize(0.45),
	transparency50: transparentize(0.5),
	transparency55: transparentize(0.55),
	transparency60: transparentize(0.6),
	transparency65: transparentize(0.65),
	transparency70: transparentize(0.7),
	transparency75: transparentize(0.75),
	transparency80: transparentize(0.8),
	transparency85: transparentize(0.85),
	transparency90: transparentize(0.9),
	transparencyFull: transparentize(1),
};

const zIndexes = {
	background: 1,
	foreground: 2,
	content: 3,
	header: 4,
};

export default {
	colors,
	tints,
	transparency,
	zIndexes,

	spacing: <{ [Type in SpacingTypes]: string }>{
		auto: 'auto',
		none: '0rem',
		xxxs: '0.2rem',
		xxs: '0.4rem',
		xs: '0.8rem',
		xssm: '1.2rem',
		sm: '1.6rem',
		smmd: '2.0rem',
		md: '2.4rem',
		lg: '3.2rem',
		lgxl: '4.8rem',
		xl: '5.6rem',
		xxl: '6.4rem',
		xxxl: '9.6rem',
		xxxxl: '12.8rem',
		xxxxxl: '15.6rem',
	},

	radius: <{ [Type in RadiusTypes]: string }>{
		none: '0rem',
		sm: '0.4rem',
		smmd: '0.6rem',
		md: '0.8rem',
		lg: '1.2rem',
		xl: '1.6rem',
		xxl: '2rem',
		xxxl: '2.4rem',
		xxxxl: '3.2rem',
		half: '50%',
	},

	lineHeights: {
		header: {
			xxl: '1.3',
			xl: '1.3',
			lg: '1.3',
			lgh: '1.3' /* Hatton */,
			md: '1.3',
			sm: '1.3',
			xs: '1.3',
			xxs: '1.3',
		},
		body: {
			xxl: '1.3',
			xl: '1.3',
			lg: '1.3',
			md: '1.3',
			sm: '1.3',
			xs: '1.3',
			xxs: '1.3',
		},
	},

	fontSizes: {
		header: <{ [Type in FontSizeTypes]: string }>{
			xxl: '6.4rem',
			xl: '5.2rem',
			lg: '4.2rem',
			md: '3.2rem',
			sm: '2.8rem',
			xs: '2.4rem',
			xxs: '2.0rem',
		},
		body: <{ [Type in FontSizeTypes]: string }>{
			xxl: '3.2rem',
			xl: '2.4rem',
			lg: '2.2rem',
			md: '2.0rem',
			smmd: '1.8rem',
			sm: '1.6rem',
			xs: '1.4rem',
			xxs: '1.2rem',
		},
	},

	shadow: {
		box: '0px 0px 8px rgba(0, 0, 0, 0.25)',
		newBox: '0px 2px 4px -1px rgba(0, 0, 0, 0.25)',
		msspBox: '0px 3px 6px rgba(0, 0, 0, 0.08)',
		menuBox: '0px 2.6px 7.67px 0px rgba(0, 0, 0, 0.09)',
		profileBox: '0px 2px 6.5px 0px rgba(0, 0, 0, 0.09)',
		sliderBox: '0px 5px 12px rgba(0, 0, 0, 0.1)',
		hoverBox: '0px 6px 13px rgba(0, 0, 0, 0.2)',
	},

	responsive: {
		sizes: <{ [Type in BreakpointTypes]: string }>{
			base: size.base,
			mobileS: size.mobileS,
			mobileM: size.mobileM,
			mobileL: size.mobileL,
			tablet: size.tablet,
			laptop: size.laptop,
			laptopM: size.laptopM,
			laptopL: size.laptopL,
			desktop: size.desktop,
		},

		breakpoints: <{ [Type in BreakpointTypes]: string }>{
			base: `(min-width: ${size.base})`,
			mobileS: `(min-width: ${size.mobileS})`,
			mobileM: `(min-width: ${size.mobileM})`,
			mobileL: `(min-width: ${size.mobileL})`,
			tablet: `(min-width: ${size.tablet})`,
			laptop: `(min-width: ${size.laptop})`,
			laptopM: `(min-width: ${size.laptopM})`,
			laptopL: `(min-width: ${size.laptopL})`,
			desktop: `(min-width: ${size.desktop})`,
		},
	},
};
