import { Property } from 'csstype';

import { ColorTypes, FontFamilyTypes, FontTypes, TintTypes } from '@folx-types/theme';

import theme from '@theme/FolxTheme';

export type Tint = (color: ColorTypes | string) => string;

type Font = {
	lineHeight: number;
	fontSize: {
		mobile: string;
		tablet: string;
	};
	fontWeight: FontTypes;
};

const IdentityTint: Tint = (color) => theme.colors[color];

export interface IComponentThemes {
	fontFamily: string;
	bodyText: {
		color: string;
		tint?: Tint;
	};
	basicText: {
		labelMarginBottom: string;
		small: Font;
		smallText: Font;
		body: Font;
		field: Font;
		button: Font;
		pill: Font;
		pillText: Font;
		fineprint: Font;
		fineprintBold: Font;
	};
	button: {
		borderRadius: string;
		padding: string;
		width?: string;
		primary: {
			bgColor: ColorTypes;
			bgTint?: Tint;
			labelColor: ColorTypes;
			focusColor: ColorTypes;
			focusTint?: Tint;
			focusLabelColor: ColorTypes;
			focusLabelTint?: Tint;
			disabledColor: ColorTypes;
			disabledTint?: Tint;
			disabledLabelColor: ColorTypes;
			disabledLabelTint?: Tint;
			border?: string;
			focusBorder?: string;
			disabledBorder?: string;
			borderRadius?: string;
			labelPadding?: string;
			labelSize?: string;
			padding?: string;
			width?: string;
		};
		secondary: {
			bgColor: ColorTypes;
			bgTint: Tint;
			labelColor: ColorTypes;
			focusColor: ColorTypes;
			focusTint: Tint;
			focusLabelColor: ColorTypes;
			focusLabelTint: Tint;
			disabledColor: ColorTypes;
			disabledTint: Tint;
			disabledLabelColor: ColorTypes;
			disabledLabelTint: Tint;
			border?: string;
			focusBorder?: string;
			disabledBorder?: string;
			borderRadius?: string;
			labelPadding?: string;
			labelSize?: string;
			padding?: string;
			width?: string;
		};
	};
	checkbox: {
		checkedColor: ColorTypes;
		hoverColor: ColorTypes;
		checkColor: ColorTypes;
		boxOutline?: boolean;
		checkedTint?: TintTypes;
		hoverTint?: TintTypes;
	};
	eyebrow: {
		textTransform: Property.TextTransform;
	};
	textLink: {
		color: string;
		desktopSize: string;
		mobileSize: string;
		bold: {
			fontWeight: FontTypes;
			fontFamily: FontFamilyTypes;
		};
		normal: {
			fontWeight: FontTypes;
			fontFamily: FontFamilyTypes;
		};
	};
	folxHeader: {
		backgroundColor: string;
		backgroundTint?: Tint;
		borderBottom?: string;
	};
	headerBar: {
		activeColor: string;
		activeTint?: Tint;
		inactiveColor: string;
		inactiveTint?: Tint;
		animated?: boolean;
	};
	headingText: {
		baseFontSize: string;
		upFontSize: string;
	};
	pageContainer: {
		background: string;
	};
	radio: {
		default: {
			innerSize: string;
			outerSize: string;
			checkedColor: string;
			hoverColor: string;
			disabledColor: string;
		};
		small: {
			innerSize: string;
			outerSize: string;
			checkedColor: string;
			hoverColor: string;
			disabledColor: string;
		};
	};
	selectableButton: {
		color: string;
		tint?: Tint;
		borderColor: string;
		borderTint?: Tint;
		disabledColor: string;
		disabledTint?: Tint;
	};
	subheadingText: {
		baseFontSize: string;
		upFontSize: string;
	};
	notification: {
		borderRadius: string;
	};
	textInput: {
		errorColor: string;
		errorFontSize: string;
		errorLineHeight: string;
	};
	listbox: {
		font: Font;
		padding: string;
	};
	gradient?: string;
	name: string;
}

export interface IFlowThemes {
	default: IComponentThemes;
	dashboard: IComponentThemes;
	prep: IComponentThemes;
	onboarding: IComponentThemes;
	vc: IComponentThemes;
	hrt_e: IComponentThemes;
	hrt_t: IComponentThemes;
}

const defaultTheme: IComponentThemes = {
	fontFamily: `'Chalet', sans-serif`,
	gradient: `radial-gradient(29% 35% at 4% 36%, #eae1b4 7%, #073aff00 100%),
		radial-gradient(18% 28% at 24% 50%, #ecdbcb 7%, #073aff00 100%),
		radial-gradient(58% 53% at 61% 81%, #edea6e 0%, #073aff00 100%),
		radial-gradient(43% 54% at 16% 95%, #f7d9ad 2%, #f7d4aa 39%, #073aff00 100%),
		radial-gradient(42% 53% at 34% 72%, #ffffffff 7%, #073aff00 100%),
		radial-gradient(36% 67% at 90% 76%, #d2d6f9 47%, #073aff00 100%),
		radial-gradient(87% 85% at 73% 37%, #97e5ed 39%, #073aff00 100%),
		linear-gradient(125deg, #efea6d 0%, #efea6d 100%)`,
	bodyText: {
		color: theme.colors.black,
	},
	basicText: {
		labelMarginBottom: theme.spacing.xs,
		body: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.8rem', tablet: '2rem' },
			fontWeight: 'normal',
		},
		small: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.6rem', tablet: '1.8rem' },
			fontWeight: 'bold',
		},
		smallText: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.6rem', tablet: '1.8rem' },
			fontWeight: 'normal',
		},
		field: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.8rem', tablet: '2rem' },
			fontWeight: 'bold',
		},
		button: {
			lineHeight: 1.3,
			fontSize: { mobile: '2rem', tablet: '2.2rem' },
			fontWeight: 'bold',
		},
		pill: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.4rem', tablet: '1.6rem' },
			fontWeight: 'bold',
		},
		pillText: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.4rem', tablet: '1.6rem' },
			fontWeight: 'normal',
		},
		fineprint: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.2rem', tablet: '1.4rem' },
			fontWeight: 'normal',
		},
		fineprintBold: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.2rem', tablet: '1.4rem' },
			fontWeight: 'bold',
		},
	},
	button: {
		borderRadius: theme.radius.xl,
		padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
		width: 'auto',
		primary: {
			labelColor: 'black',
			labelSize: theme.fontSizes.body.sm,
			bgColor: 'transparent',
			bgTint: IdentityTint,
			border: '0.1rem solid black',
			focusColor: 'black',
			focusTint: IdentityTint,
			focusLabelColor: 'white',
			focusLabelTint: IdentityTint,
			disabledColor: 'darkGray',
			disabledTint: IdentityTint,
			disabledLabelColor: 'black',
			disabledLabelTint: theme.transparency.transparency50,
		},
		secondary: {
			labelColor: 'white',
			labelSize: theme.fontSizes.body.sm,
			bgColor: 'black',
			bgTint: IdentityTint,
			focusBorder: '0.1rem solid black',
			focusColor: 'transparent',
			focusTint: IdentityTint,
			focusLabelColor: 'black',
			focusLabelTint: IdentityTint,
			disabledColor: 'darkGray',
			disabledTint: IdentityTint,
			disabledLabelColor: 'black',
			disabledLabelTint: theme.transparency.transparency50,
		},
	},
	checkbox: {
		checkedColor: 'black',
		hoverColor: 'cyan',
		hoverTint: 'lighten80',
		checkColor: 'white',
	},
	radio: {
		default: {
			innerSize: theme.spacing.sm,
			outerSize: theme.spacing.md,
			checkedColor: theme.colors.lilacMedium,
			hoverColor: theme.colors.lightBeige,
			disabledColor: theme.colors.disabledGray,
		},
		small: {
			innerSize: theme.spacing.xssm,
			outerSize: theme.spacing.smmd,
			checkedColor: theme.colors.black,
			hoverColor: theme.colors.black,
			disabledColor: theme.colors.disabledGray,
		},
	},
	eyebrow: {
		textTransform: 'none',
	},
	textLink: {
		color: theme.colors.black,
		desktopSize: theme.fontSizes.body.md,
		mobileSize: theme.fontSizes.body.sm,
		bold: {
			fontFamily: `'Montserrat', sans-serif`,
			fontWeight: 'bold',
		},
		normal: {
			fontFamily: `'Chalet', sans-serif`,
			fontWeight: 'normal',
		},
	},
	folxHeader: {
		backgroundColor: theme.colors.white,
	},
	headerBar: {
		activeColor: theme.colors.lilac,
		inactiveColor: theme.colors.gray,
		animated: true,
	},
	headingText: {
		baseFontSize: theme.fontSizes.header.sm,
		upFontSize: theme.fontSizes.header.md,
	},
	subheadingText: {
		baseFontSize: theme.fontSizes.body.md,
		upFontSize: theme.fontSizes.body.lg,
	},
	notification: {
		borderRadius: theme.radius.none,
	},
	pageContainer: {
		background: theme.colors.white,
	},
	selectableButton: {
		color: theme.colors.cyan,
		tint: theme.tints.lighten80,
		borderColor: theme.colors.black,
		disabledColor: theme.colors.lightGray,
	},
	textInput: {
		errorColor: theme.tints.darken40(theme.colors.red),
		errorFontSize: theme.fontSizes.body.sm,
		errorLineHeight: theme.lineHeights.body.sm,
	},
	listbox: {
		font: {
			lineHeight: 1.3,
			fontSize: { mobile: '1.8rem', tablet: '2rem' },
			fontWeight: 'normal',
		},
		padding: '2rem 1rem',
	},
	name: 'default',
};

const flowThemes: IFlowThemes = {
	default: defaultTheme,
	dashboard: {
		...defaultTheme,
		basicText: {
			...defaultTheme.basicText,
			labelMarginBottom: theme.spacing.sm,
			body: {
				...defaultTheme.basicText.body,
				fontSize: { mobile: '1.6rem', tablet: '2rem' },
			},
			field: {
				...defaultTheme.basicText.field,
				fontSize: { mobile: '1.6rem', tablet: '2rem' },
			},
		},
		button: {
			...defaultTheme.button,
			borderRadius: theme.radius.xl,
			padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
			primary: {
				...defaultTheme.button.primary,
				labelColor: 'black',
				bgColor: 'transparent',
				border: '0.1rem solid black',
				borderRadius: '2rem',
				padding: `1.4rem ${theme.spacing.md}`,
				labelSize: theme.fontSizes.body.xxs,
				width: 'fit-content',
			},
			secondary: {
				...defaultTheme.button.secondary,
				focusBorder: '0.1rem solid black',
				focusColor: 'transparent',
				focusLabelColor: 'black',
				labelSize: theme.fontSizes.body.sm,
			},
		},
		eyebrow: {
			textTransform: 'none',
		},
		notification: {
			borderRadius: theme.radius.md,
		},
		pageContainer: {
			background: theme.colors.lightGray,
		},
		folxHeader: {
			backgroundColor: theme.colors.white,
			borderBottom: `solid 1px ${theme.colors.gray}`,
		},
		name: 'dashboard',
		headingText: {
			...defaultTheme.headingText,
			baseFontSize: theme.fontSizes.body.lg,
		},
		subheadingText: {
			...defaultTheme.subheadingText,
			baseFontSize: theme.fontSizes.body.smmd,
			upFontSize: theme.fontSizes.body.md,
		},
	},
	onboarding: {
		...defaultTheme,
		pageContainer: {
			background: theme.colors.white,
		},
		textInput: {
			errorColor: theme.tints.darken40(theme.colors.red),
			errorFontSize: theme.fontSizes.body.sm,
			errorLineHeight: theme.lineHeights.body.sm,
		},
		name: 'onboarding',
	},
	vc: {
		...defaultTheme,
		button: {
			...defaultTheme.button,
			borderRadius: theme.radius.xl,
			padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
		},
		headerBar: {
			activeColor: theme.colors.beige,
			inactiveColor: theme.colors.beige,
			inactiveTint: theme.tints.lighten80,
			animated: true,
		},
		selectableButton: {
			color: theme.colors.beige,
			tint: theme.tints.lighten80,
			borderColor: theme.colors.beige,
			disabledColor: theme.colors.lightGray,
		},
		textInput: {
			errorColor: theme.tints.darken40(theme.colors.red),
			errorFontSize: theme.fontSizes.body.sm,
			errorLineHeight: theme.lineHeights.body.sm,
		},
		name: 'vc',
	},
	hrt_t: {
		...defaultTheme,
		button: {
			...defaultTheme.button,
			width: '100%',
		},
		folxHeader: {
			backgroundColor: theme.colors.white,
		},
		headerBar: {
			activeColor: theme.colors.cyan,
			inactiveColor: theme.colors.gray,
			animated: true,
		},
		selectableButton: {
			color: theme.colors.cyan,
			tint: theme.tints.lighten80,
			borderColor: theme.colors.black,
			disabledColor: theme.colors.lightGray,
		},
		textInput: {
			errorColor: theme.tints.darken40(theme.colors.red),
			errorFontSize: theme.fontSizes.body.sm,
			errorLineHeight: theme.lineHeights.body.sm,
		},
		name: 'hrt_t',
	},
	hrt_e: {
		...defaultTheme,
		button: {
			...defaultTheme.button,
			width: '100%',
		},
		headerBar: {
			activeColor: theme.colors.cyan,
			inactiveColor: theme.colors.gray,
			animated: true,
		},
		selectableButton: {
			color: theme.colors.cyan,
			tint: theme.tints.lighten80,
			borderColor: theme.colors.black,
			disabledColor: theme.colors.lightGray,
		},
		textInput: {
			errorColor: theme.tints.darken40(theme.colors.red),
			errorFontSize: theme.fontSizes.body.sm,
			errorLineHeight: theme.lineHeights.body.sm,
		},
		name: 'hrt_e',
	},
};

export default flowThemes;
