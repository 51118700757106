import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import React, { ReactNode } from 'react';

import { ApiEndpoints } from '@utils/endpoints';
import { errorTypes } from '@utils/error';
import { isBrowser } from '@utils/isBrowser';

import { getAuthToken } from '@providers/AuthProvider';

export const errorLink = onError(({ networkError = {} as any, graphQLErrors }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path }) => {
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
			datadogRum.addError(message, {
				errorType: errorTypes.apolloGraphql,
				locations: locations,
				path: path,
			});
		});
	}
	if (networkError) {
		console.log(`[Network err]: Message: ${networkError}`);
		switch (networkError.statusCode) {
			case 400:
			case 500:
				datadogRum.addError(networkError, { errorTypes: errorTypes.apolloNetwork });
				break;
			default:
		}
	}
});

export const authLink = setContext(async (_, { headers }) => {
	const authorizationToken = await getAuthToken();
	return {
		headers: {
			...headers,
			authorizationToken,
		},
	};
});

export const apolloClient = () => {
	const httpLink = new HttpLink({
		uri: ApiEndpoints.folxGraphql,
		...(!isBrowser && {
			fetch: () => {
				throw new Error("Can't make a dynamic query outside of a browser.");
			},
		}),
	});

	const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link,
	});

	return client;
};

const FolxApolloProvider = ({ children }: { children: ReactNode }) => {
	const client = apolloClient();
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default FolxApolloProvider;
