import React, { FC, useEffect } from 'react';

import { useTracking } from '@hooks/useTracking';

import { LocationData } from '@folx-types/routing';

import { GlobalStyles, ResetStyles } from '../GlobalStyles';

interface LayoutProps {
	children: React.ReactNode;
	location: LocationData;
}

const Layout: FC<LayoutProps> = ({ children, location }) => {
	const { trackEvent } = useTracking();

	useEffect(() => {
		if (location?.pathname) {
			trackEvent('view-page', { url: location?.pathname });
		}
	}, [location]);

	return (
		<>
			<ResetStyles />
			{location?.pathname !== '/graphiql_v2/' && <GlobalStyles />}
			<>{children}</>
		</>
	);
};

export default Layout;
