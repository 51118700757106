import { shade, tint, transparentize } from 'polished';

import { SpacingTypes, ColorTypes, RadiusTypes } from '@folx-types/theme';

const size = {
	base: '0px',
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px',
};

const colors: { [Type in ColorTypes]: string } = {
	beige: '#f4e3cf',
	black: '#000000',
	border: '#d7d7d7',
	borderBeige: '#e9e1d6',
	cyan: '#00ffff',
	darkBeige: '#f0eae2',
	darkCyan: '#d3f0f0',
	darkGray: '#ebebeb',
	darkLavender: '#ddd7e3',
	darkPink: '#f57eb6',
	darkYellow: '#f1f1d3',
	gray: '#ebebeb',
	green: '#00ff00',
	lavender: '#c79aff',
	lightBeige: '#f8f7f5',
	lightCyan: '#e0fcfc',
	lightGray: '#f5f5f5',
	lightLavender: '#f4effa',
	lightPink: '#fccbe5',
	lightYellow: '#fafae0',
	lilac: '#c79aff',
	pink: '#ff62b5',
	red: '#ff0000',
	transparent: '#00000000',
	white: '#ffffff',
	yellow: '#ffff00',
};

const tints = {
	darken20: shade(0.2),
	darken30: shade(0.3),
	darken40: shade(0.4),
	darken50: shade(0.5),
	darken60: shade(0.6),
	darken70: shade(0.7),
	darken80: shade(0.8),
	lighten20: tint(0.2),
	lighten30: tint(0.3),
	lighten40: tint(0.4),
	lighten50: tint(0.5),
	lighten60: tint(0.6),
	lighten70: tint(0.7),
	lighten80: tint(0.8),
};

const transparency = {
	transparency10: transparentize(0.1),
	transparency20: transparentize(0.2),
	transparency30: transparentize(0.3),
	transparency40: transparentize(0.4),
	transparency50: transparentize(0.5),
	transparency60: transparentize(0.6),
	transparency70: transparentize(0.7),
	transparency80: transparentize(0.8),
	transparency90: transparentize(0.9),
};

const zIndexes = {
	background: 1,
	foreground: 2,
	content: 3,
	header: 4,
	dialog: 5,
};

export default {
	colors,
	tints,
	transparency,
	zIndexes,

	fullWidthBackground: `max-width: 100% !important;
			> * {
				margin-left: auto;
				margin-right: auto;

			}`,

	maxWidthCenter: `
	margin-left: auto;
	margin-right: auto;`,

	spacing: <{ [Type in SpacingTypes]: string }>{
		xs: '0.8rem',
		sm: '1.6rem',
		md: '2.4rem',
		lg: '3.2rem',
		lgxl: '4.8rem',
		xl: '5.6rem',
		xxl: '6.4rem',
		xxxl: '9.6rem',
		xxxxl: '12.8rem',
	},

	radius: <{ [Type in RadiusTypes]: string }>{
		none: '0rem',
		sm: '0.4rem',
		md: '0.8rem',
		lg: '1.5rem',
		xl: '2.0rem',
		xxl: '3.0rem',
		xxxl: '5.0rem',
		half: '50%',
	},

	lineHeights: {
		header: {
			xxl: '1.3',
			xl: '1.3',
			lg: '1.3',
			lgh: '1.3' /* Hatton */,
			md: '1.3',
			sm: '1.3',
			xs: '1.3',
		},
		body: {
			xxl: '1.3',
			xl: '1.3',
			lg: '1.3',
			md: '1.3',
			sm: '1.3',
			xs: '1.5',
			label: '1.8',
		},
	},

	fontSizes: {
		header: {
			xxl: '7.2rem',
			xl: '5rem',
			lg: '4.2rem',
			md: '3.4rem',
			sm: '2.4rem',
			xs: '1.8rem',
		},
		headerMobile: {
			xxl: '3.4rem',
			xl: '3rem',
			lg: '2.6rem',
			md: '2.2rem',
			sm: '1.8rem',
			xs: '1.6rem',
		},
		body: {
			xxl: '3.2rem',
			xl: '2.4rem',
			lg: '2.2rem',
			md: '2.0rem',
			sm: '1.6rem',
			xs: '1.2rem',
			label: '1.4rem',
		},
	},

	fonts: {
		primary: 'Hatton',
		secondary: 'Chalet-LondonSixty, sans-serif',
		tertiary: 'Montserrat',
		montserrat: 'Montserrat',
	},

	shadow: {
		box: '0px 0px 8px rgba(0, 0, 0, 0.25)',
	},

	responsive: {
		sizes: {
			base: size.base,
			mobileS: size.mobileS,
			mobileM: size.mobileM,
			mobileL: size.mobileL,
			tablet: size.tablet,
			laptop: size.laptop,
			laptopL: size.laptopL,
			desktop: size.desktop,
		},

		breakpoints: {
			base: `(min-width: ${size.base})`,
			mobileS: `(min-width: ${size.mobileS})`,
			mobileM: `(min-width: ${size.mobileM})`,
			mobileL: `(min-width: ${size.mobileL})`,
			tablet: `(min-width: ${size.tablet})`,
			laptop: `(min-width: ${size.laptop})`,
			laptopL: `(min-width: ${size.laptopL})`,
			desktop: `(min-width: ${size.desktop})`,
		},
	},
};
