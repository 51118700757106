import { HubCallback } from '@aws-amplify/core';
import { datadogRum } from '@datadog/browser-rum';
import { CognitoUser } from 'amazon-cognito-identity-js';
import type { Auth as AuthClass, Hub as HubClass } from 'aws-amplify';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import React, { FC, useContext, useEffect, useReducer, useState } from 'react';

import { ApiEndpoints } from '@utils/endpoints';

export const isBrowser = typeof window !== `undefined`;

const { Auth, Hub }: { Auth: typeof AuthClass; Hub: typeof HubClass } = isBrowser
	? require('aws-amplify')
	: { Auth: {}, Hub: {} };

type User = CognitoUser;

interface IAuthContext {
	user?: User;
	updateUser: (user?: User) => void;
	initialized: boolean;
}

const AuthContext = React.createContext<IAuthContext>({
	updateUser: (user) => {},
	initialized: false,
});

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	const [{ user, initialized }, updateUser] = useReducer(
		({ user }: { user: User | undefined; initialized: boolean }, newUser: User | undefined) => {
			if (newUser && !newUser?.getSignInUserSession()?.getIdToken().payload.email_verified) {
				Auth.signOut();
				return { user: undefined, initialized: true };
			} else if (newUser?.getUsername() != user?.getUsername()) {
				return { user: newUser, initialized: true };
			}
			return { user, initialized: true };
		},
		{ user: undefined, initialized: false }
	);

	useEffect(() => {
		if (!isBrowser) return;
		Auth.currentAuthenticatedUser()
			.then(updateUser)
			.catch(() => updateUser(undefined));
	}, []);

	useEffect(() => {
		if (user) {
			const userId = user.getUsername();
			const email = user.getSignInUserSession()?.getIdToken().payload.email ?? '';
			mixpanel.identify(userId);
			mixpanel.people.set({
				USER_ID: userId,
				IS_FOLX_USER: email.endsWith('@folxhealth.com'),
			});
			datadogRum.setUser({ id: userId });
		} else {
			mixpanel.reset();
			datadogRum.removeUser();
		}
	}, [user]);

	// Try to catch any auth events that don't go through this provider.
	useEffect(() => {
		if (!isBrowser) return;
		const listener: HubCallback = (event) => {
			switch (event.payload.event) {
				case 'signIn':
				case 'configured':
					Auth.currentAuthenticatedUser()
						.then(updateUser)
						.catch(() => updateUser(undefined));
					break;
				case 'signOut':
				case 'signIn_failure':
				case 'tokenRefresh_failure':
					updateUser(undefined);
					break;
			}
		};

		Hub.listen('auth', listener);

		const cleanup = () => {
			Hub.remove('auth', listener);
		};
		return cleanup;
	}, []);

	return (
		<AuthContext.Provider value={{ user, updateUser, initialized }}>
			{children}
		</AuthContext.Provider>
	);
};

export type SignInErrorTypes =
	| 'NOT_AUTHORIZED'
	| 'PASSWORD_RESET_REQUIRED'
	| 'ALREADY_LOGGED_IN'
	| 'SERVICE_ERROR';

// The error cases from Amplify on sign-in aren't well-documented or easy to use
// so let's wrap them with something easier
export class SignInError extends Error {
	type: SignInErrorTypes;

	constructor(type: SignInErrorTypes, message?: string) {
		super(message ?? `Sign in error: ${type}`);
		this.type = type;
	}
}

export type SignUpErrorTypes =
	| 'INVALID_PASSWORD'
	| 'USER_EXISTS'
	| 'ALREADY_LOGGED_IN'
	| 'SERVICE_ERROR';

export class SignUpError extends Error {
	type: SignUpErrorTypes;

	constructor(type: SignUpErrorTypes, message?: string) {
		super(message ?? `Sign up error: ${type}`);
		this.type = type;
	}
}

export type VerifySignUpErrorTypes =
	| 'WRONG_CODE'
	| 'EXPIRED_CODE'
	| 'ALREADY_LOGGED_IN'
	| 'SERVICE_ERROR';

export class VerifySignUpError extends Error {
	type: VerifySignUpErrorTypes;

	constructor(type: VerifySignUpErrorTypes, message?: string) {
		super(message ?? `Verify sign up error: ${type}`);
		this.type = type;
	}
}

export type ResetPasswordErrorTypes =
	| 'WRONG_CODE'
	| 'EXPIRED_CODE'
	| 'INVALID_PASSWORD'
	| 'ALREADY_LOGGED_IN'
	| 'SERVICE_ERROR';

export class ResetPasswordError extends Error {
	type: ResetPasswordErrorTypes;

	constructor(type: ResetPasswordErrorTypes, message?: string) {
		super(message ?? `Reset password error: ${type}`);
		this.type = type;
	}
}

export type ConfirmPasswordErrorTypes =
	| 'INVALID_PASSWORD'
	| 'NOT_AUTHORIZED'
	| 'PASSWORD_RESET_REQUIRED'
	| 'USER_ERROR'
	| 'SERVICE_ERROR';

export class ConfirmPasswordError extends Error {
	type: ConfirmPasswordErrorTypes;

	constructor(type: ConfirmPasswordErrorTypes, message?: string) {
		super(message ?? `Change password error: ${type}`);
		this.type = type;
	}
}

// Dependency loop break; ApolloProvider needs getAuthToken from AuthProvider,
// but AuthProvider needs ApolloProvider set up.
// So, we provide a version of this function which works for ApolloProvider.
export const getAuthToken = async () => {
	if (!isBrowser) return '';
	try {
		const session = await Auth.currentSession();
		const idToken = await session.getIdToken();
		return idToken.getJwtToken();
	} catch (e) {
		console.log(`Error getting auth token: ${e.message}`);
		return '';
	}
};

export const useAuth = () => {
	const { user, updateUser, initialized } = useContext(AuthContext);

	const signIn = async (email: string, password: string) => {
		if (!isBrowser) return;
		if (user) {
			throw new SignInError('ALREADY_LOGGED_IN', "Can't sign in when already signed in.");
		}

		try {
			await Auth.signIn(email, password);
		} catch (e) {
			if ('code' in e) {
				switch (e.code) {
					case 'NotAuthorizedException':
						throw new SignInError('NOT_AUTHORIZED', e.message);
					case 'PasswordResetRequiredException':
						throw new SignInError('PASSWORD_RESET_REQUIRED', e.message);
					default:
						throw new SignInError('SERVICE_ERROR', e.message);
				}
			} else {
				throw e;
			}
		}

		const currentUser = await Auth.currentAuthenticatedUser();
		updateUser(currentUser);
	};

	const signOut = async () => {
		if (!isBrowser) return;
		await Auth.signOut();
		if (window.localStorage.getItem('prep')) {
			window.localStorage.removeItem('prep');
			window.localStorage.removeItem('prep_step');
		}
		updateUser(undefined);
	};

	const signUp = async (email: string) => {
		if (user) {
			throw new SignUpError('ALREADY_LOGGED_IN', "Can't sign up when already logged in.");
		}

		try {
			await axios.post(
				`${ApiEndpoints.public!}/signup`,
				{
					email,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				}
			);
		} catch (e) {
			if ('code' in e) {
				switch (e.code) {
					case 'InvalidPasswordException':
						throw new SignUpError('INVALID_PASSWORD', e.message);
					case 'UserLambdaValidationException':
						if (e.message.match(/ERROR_DUPLICATE_EMAIL_FOUND/)) {
							throw new SignUpError('USER_EXISTS');
						} else {
							throw new SignUpError('SERVICE_ERROR', e.message);
						}
					default:
						throw new SignUpError('SERVICE_ERROR', e.message);
				}
			} else {
				throw e;
			}
		}
	};

	const verifySignUp = async (
		email: string,
		password: string,
		code: string,
		state: string,
		firstName?: string,
		lastName?: string
	) => {
		if (user) {
			throw new VerifySignUpError(
				'ALREADY_LOGGED_IN',
				"Can't verify signup when already logged in."
			);
		}

		let userid = '';

		try {
			const response = await axios.post(
				`${ApiEndpoints.public!}/signup-verify-code`,
				{
					email,
					code,
					password,
					state,
					firstName,
					lastName,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				}
			);
			mixpanel.people.set({
				'Sign up date': new Date().toISOString(),
				USER_ID: userid,
				IS_FOLX_USER: email.endsWith('@folxhealth.com'),
			});
			userid = response.data.userid;
		} catch (e) {
			throw new VerifySignUpError('SERVICE_ERROR', e.message);
		}
		console.log('Code verification successful.');
		await signIn(userid, password);
	};

	const requestNewCode = async (userid: string) => {
		if (user) throw new Error("Can't request a new code when signed in.");
		await Auth.resendSignUp(userid);
	};

	const requestPasswordReset = async (email: string) => {
		if (user) throw new Error("Can't reset password when signed in.");
		await Auth.forgotPassword(email);
	};

	const resetPassword = async (email: string, code: string, password: string) => {
		if (user)
			throw new ResetPasswordError('ALREADY_LOGGED_IN', "Can't reset password when signed in.");

		try {
			await Auth.forgotPasswordSubmit(email, code, password);
		} catch (e) {
			if ('code' in e) {
				switch (e.code) {
					case 'CodeMismatchException':
						throw new ResetPasswordError('WRONG_CODE', e.message);
					case 'ExpiredCodeException':
						throw new ResetPasswordError('EXPIRED_CODE', e.message);
					case 'InvalidPasswordException':
						throw new ResetPasswordError('INVALID_PASSWORD', e.message);
					default:
						throw new ResetPasswordError('SERVICE_ERROR', e.message);
				}
			} else {
				throw e;
			}
		}
	};

	const providerGetAuthToken = async () => {
		if (user === undefined) return '';
		return await getAuthToken();
	};

	const confirmPassword = async (email: string, oldPassword: string, newPassword: string) => {
		try {
			if (!email || !oldPassword || !newPassword) {
				throw new ConfirmPasswordError('SERVICE_ERROR', 'All parameters are required.');
			}
			const user = await Auth.signIn(email, oldPassword);
			if (!user.username) {
				throw new ConfirmPasswordError('SERVICE_ERROR', 'Did not receive username');
			}
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				await Auth.completeNewPassword(user, newPassword);
				await updateUser(await Auth.currentAuthenticatedUser());
			}
		} catch (e: any) {
			if ('code' in e) {
				switch (e.code) {
					case 'InvalidParameterException':
						throw new ConfirmPasswordError('INVALID_PASSWORD', e.message);
					case 'InvalidPasswordException':
						throw new ConfirmPasswordError('INVALID_PASSWORD', e.message);
					case 'NotAuthorizedException':
						throw new ConfirmPasswordError('NOT_AUTHORIZED', e.message);
					case 'PasswordResetRequiredException':
						throw new ConfirmPasswordError('PASSWORD_RESET_REQUIRED', e.message);
					case 'UserNotConfirmedException':
						throw new ConfirmPasswordError('USER_ERROR', e.message);
					case 'UserNotFoundException':
						throw new ConfirmPasswordError('USER_ERROR', e.message);
					default:
						throw new ConfirmPasswordError('SERVICE_ERROR', e.message);
				}
			}
		}
	};

	return {
		user: user as CognitoUser,
		userId: (user?.getUsername() ?? '') as string,
		email: user?.getSignInUserSession()?.getIdToken().payload.email as string,
		loggedIn: !!user,
		initialized,
		signIn,
		signOut,
		signUp,
		verifySignUp,
		requestNewCode,
		requestPasswordReset,
		resetPassword,
		confirmPassword,
		getAuthToken: providerGetAuthToken,
	};
};
