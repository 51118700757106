import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import ApolloProvider from './src/providers/ApolloProvider';
import { AuthProvider } from './src/providers/AuthProvider';
import { EntitlementsProvider } from './src/providers/EntitlementsProvider';
import GraphQLv2Context, { graphQLv2ApolloClient } from './src/providers/GraphQLv2ContextProvider';
import createStore from './src/state/createStore';
import { getThemeForFlow } from './src/theme/FlowThemes';

export default ({ element }) => {
	const store = createStore();
	const graphqlV2Client = { client: graphQLv2ApolloClient() };
	return (
		<ThemeProvider theme={getThemeForFlow('default')}>
			<AuthProvider>
				<ReduxProvider store={store}>
					<ApolloProvider>
						<GraphQLv2Context.Provider value={graphqlV2Client}>
							<EntitlementsProvider>{element}</EntitlementsProvider>
						</GraphQLv2Context.Provider>
					</ApolloProvider>
				</ReduxProvider>
			</AuthProvider>
		</ThemeProvider>
	);
};
