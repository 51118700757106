export const commonErrors = {
	inputRequired: 'Enter a response',
	responseRequired: 'Response required',
	selectionRequired: 'Select an option',
	multiselectRequired: 'Select at least one option',
	fileSize: 'File size cannot exceed 3.4MB',
	checkRequired: 'Check required',
	invalidZip: 'Enter a valid ZIP',
};

export const dropzoneErrors = {
	invalid: 'Invalid upload. Please upload a supported file type.',
	required: 'Upload required. Please upload a supported file type.',
	failed: 'Upload failed. Please try again.',
};

export const errorTypes = {
	apolloGraphql: 'Apollo: GraphQL Error',
	apolloNetwork: 'Apollo: Network Error',
};
